<template>
  <l-map
    v-if="!isLoading"
    style="height: 330px; z-index: 1 !important;"
    :zoom="zoom"
    :center="center"
    @click="setPos"
  >
    <l-tile-layer :url="url"></l-tile-layer>
    <l-marker :lat-lng="markerLatLng"></l-marker>
  </l-map>
</template>

<script>
import { mapMutations, mapState } from 'vuex'

export default {
  data() {
    return {
      isLoading: true,
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      zoom: 8,
      center: [],
      markerLatLng: [],
    }
  },
  computed: {
    ...mapState({
      onlyShow: state => state.app.onlyShow,
    }),
  },
  mounted() {
    if (
      this.$store.state.app.geotag.longitud === undefined
      || this.$store.state.app.geotag.latitud === undefined
      || this.$store.state.app.geotag.longitud === null
      || this.$store.state.app.geotag.latitud === null
    ) {
      this.load()
    } else {
      this.center = [this.$store.state.app.geotag.latitud, this.$store.state.app.geotag.longitud]
      this.markerLatLng = [this.$store.state.app.geotag.latitud, this.$store.state.app.geotag.longitud]
      this.isLoading = false
    }
  },
  methods: {
    ...mapMutations(['updateGeoTag', 'updateAirport']),
    load() {
      this.axios
        .get('https://ipapi.co/json')
        .then(res => {
          const { latitude } = res.data
          const { longitude } = res.data
          this.center = [latitude, longitude]
          this.markerLatLng = [latitude, longitude]

          const geotag = { longitud: longitude, latitud: latitude }

          this.updateGeoTag(geotag)
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => (this.isLoading = false))
    },
    setPos(e) {
      if (!this.onlyShow) {
        const coord = e.latlng
        const latitude = coord.lat
        const longitude = coord.lng

        this.center = [latitude, longitude]
        this.markerLatLng = [latitude, longitude]

        const geotag = { longitud: longitude, latitud: latitude }

        this.updateGeoTag(geotag)
        this.callNominatum(latitude, longitude)
      }
    },
    callNominatum(lat, long) {
      this.axios
        .get(`https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${long}&format=json`)
        .then(res => {
          const { address } = res.data

          const json = {}
          if (address.country) {
            json.country = address.country
          }
          if (address.province) {
            json.state = address.province
          }
          if (address.state) {
            json.state = address.state
          }
          if (address.city) {
            json.city = address.city
          }

          this.updateAirport(json)

          /* console.log(address)
          const { city } = address
          const { country } = address
          const { state } = address
          const { county } = address
          const { postcode } = address
          const { road } = address
          const { suburb } = address
          const { neighbourhood } = address
          const { county_code } = address
          const { state_code } = address
          const { country_code } = address
          const { country_code3 } = address
          const { country_name } = address
          const { continent } = address
          const { latitude } = address
          const { longitude } = address
          const { geohash } = address
          const { boundingbox } = address
          const { polygon } = address
          const { osm_id } = address
          const { osm_type } = address
          const { place_id } = address
          const { licence } = address
          const { licence_url } = address
          const { osm_url } = address
          const { wikipedia } = address
          const { description } = address
          const { name } = address
          const { place_rank } = address
          const { place_type } = address
          const { place_type_code } = address
          const { place_type_name } = address
          const { place_type_url } = address
          const { place_url } = address
          const { place_url_short } = address
          const { place_url_short_name } = address
          const { place_url_short_name_en } = address
          const { place_url_short_name_es } = address
          const { place_url_short_name_fr } = address
          const { place_url_short_name_it } = address
          const { place_url_short_name_pt } = address */
        })
    },
  },
}
</script>

<style>
/*.leaflet-control-attribution a {
    text-decoration: none;
    visibility: hidden;
}
.leaflet-container .leaflet-control-attribution {
    background: #fff;
    background: rgba(255, 255, 255, 0);
    margin: 0;
}*/
</style>
